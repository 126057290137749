import NavBar from "../components/nav/NavBar";
import { BrowserRouter, Route, Routes } from 'react-router-dom';

export default function About() {
    return (
        <main>
            <div>
                <p>About me</p>
                <btn>Test Button</btn>
            </div>
        </main>
    );
}